<template>
  <div>
    <a-spin :spinning="loading">
      <div class="ft20 ftw500 cl-main">
        案例展示<span class="ft14 cl-notice ml5"
          >案例仅供演示，如需定制请咨询客服。</span
        >
      </div>

      <!-- <div class="mt24 flex space">
				<div class="base-app-item" v-for="(item,index) in base_applications" :key="index">
					<div class="pd20 flex alcenter space">
						<div class="base-app-l flex alcenter">
							<img v-if="item.logo!=null" :src="item.logo" />
							<img v-else src="../assets/image/application_icon_base01@2x.png" />
							<div class="ml20 base-app-info">
								<div class="flex alcenter">
									<span class="ft18 ftw500 cl-main">{{item.name}}</span>
									<div class="test-tag ml10">免费试用{{item.trial_days}}天</div>
								</div>
								<div class="mt16 cl-main ftw500 ft14">适用场景：</div>
								
								<div class="mt8 ft12 cl-info" style="line-height: 18px;">
									{{item.scene}}
								</div>
							</div>
						</div>
						<div>
							<a-button @click="addMendian(item.app_id)" type="primary" ghost>免费试用</a-button>
						</div>
					</div>
					<img v-if="item.is_tui==1" class="tui" src="../assets/image/label_recommend@2x.png" />
				</div>		
			</div> -->

      <div class="mt20 flex alcenter">
        <span class="ft18 cl-main ft600" style="color: #999999">行业:</span>
        <div class="app-cat flex alcenter">
          <!-- <div class="app-cat-item on" v-if="search.parent_type_id==1">全部</div>
					<div class="app-cat-item clickAct" v-else @click="selectIt(1)">全部</div> -->
          <template v-for="(item, index) in type_list">
            <div
              class="app-cat-item on"
              v-if="item.industry_id == search.type_id"
            >
              {{ item.industry_name }}
            </div>
            <div
              class="app-cat-item clickAct"
              v-if="item.industry_id != search.type_id"
              @click="selectIts(item.industry_id)"
            >
              {{ item.industry_name }}
            </div>
          </template>
        </div>
      </div>
      <div class="mt20 flex alcenter">
        <span class="ft18 cl-main ft600" style="color: #999999">子行业:</span>
        <div class="app-cat flex alcenter">
          <div class="app-cat-item on" v-if="search.parent_type_id == 1">
            全部
          </div>
          <div class="app-cat-item clickAct" v-else @click="selectIt(1)">
            全部
          </div>
          <template v-for="(item, index) in zitype_list">
            <div
              class="app-cat-item on"
              v-if="
                item.industry_id == search.parent_type_id &&
                item.industry_id != 1
              "
            >
              {{ item.industry_name }}
            </div>
            <div
              class="app-cat-item clickAct"
              v-if="
                item.industry_id != search.parent_type_id &&
                item.industry_id != 1
              "
              @click="selectIt(item.industry_id)"
            >
              {{ item.industry_name }}
            </div>
          </template>
        </div>
      </div>
      <div class="mt24">
        <a-row :gutter="[20, 20]">
          <a-col :span="6" v-for="(item, index) in applications" :key="index">
            <div class="scheme-item">
              <img
                :src="item.design_sketch"
                alt=""
                style="width: 100%; height: 100%; border-radius: 10px"
              />
              <!-- <div>
							   <div class="text-center">
								   <img v-if="item.logo != null" width="80" height="80" :src="item.logo" />
								   <img v-else width="80" height="80" src="../assets/image/application_icon_scheme01@2x.png" />
							   </div>
							   <div class="text-center mt20 ft18 ftw500 cl-main">
								   {{item.name}}
							   </div>
							   <div class="flex center mt10">
								   <div class="test-tag">免费试用{{item.trial_days}}天</div>
							   </div>
							   <div class="mt16 text-center ft12 cl-info" style="line-height: 18px;">
								   {{item.scene}}
							   </div>
							   <div class="flex center mt24">
								   <a-button  @click="addMendian(item.app_id)" type="primary" ghost>免费试用</a-button>
							   </div>
						   </div> -->
              <!-- <img v-if="item.is_tui==1" class="tui" src="../assets/image/label_recommend@2x.png" /> -->
              <img :src="item.code_img" class="code" alt="" />
            </div>
          </a-col>

          <a-col :span="6">
            <!-- <div class="scheme-item">
						  <div class="pt50">
							  <div class="text-center">
								  <img style="width: 80px; height: 80px;" src="../assets/image/application_icon_more@2x.png" />
							  </div>
							  <div class="text-center mt16 ft18 cl-main ftw500">更多解决方案正在研发中</div>
							  <div class="mt16 cl-notice ft12 text-center">敬请期待…</div>
						  </div>
					  </div> -->
          </a-col>
        </a-row>
      </div>
    </a-spin>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showForm: false,
      loading: false,
      confirmLoading: false,
      account_id: 0,
      app_id: 0,
      search: {
        type_id: 1,
        parent_type_id: 1,
      },
      form: {
        name: "",
      },
      type_list: [],
      zitype_list: [],
      base_applications: [],
      applications: [],
      appnum: 0,
    };
  },
  created() {
    this.getLists();
    this.cate();
  },
  methods: {
    cate() {
      this.$http
        .api("platform/account/getShopIndustryList", {})
        .then((res) => {
          this.type_list = res.industry;
          console.log(this.type_list);
          this.zitype_list = res.children;
        })
        .catch((res) => {});
    },
    getLists() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/account/getShopTemplateList", {
          parent_type_id:
            this.search.parent_type_id == 1 ? "" : this.search.parent_type_id,
          type_id: this.search.type_id,
        })
        .then((res) => {
          this.loading = false;
          this.applications = res.datas;
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },

    selectIt(id) {
      this.search.parent_type_id = id;
      this.getLists();
    },
    selectIts(id) {
      this.search.type_id = id;
      this.$http
        .api("platform/account/showIndustry", {
          industryId: this.search.type_id,
        })
        .then((res) => {
          this.zitype_list = res.industry;
          this.search.parent_type_id = 1;
          this.getLists();
        })
        .catch((res) => {});
    },

    handleOk() {
      if (this.confirmLoading == true) return;
      this.confirmLoading = true;
      this.$http
        .api("platform/account/createShop", {
          app_id: this.app_id,
          name: this.form.name,
        })
        .then((res) => {
          this.confirmLoading = false;
          this.showForm = false;
          this.$message.success("创建商铺成功", 1, () => {
            window.open(
              res.base_url +
                "auth?shop_token=" +
                encodeURIComponent(res.shop_token)
            );
          });
        })
        .catch((res) => {
          this.confirmLoading = false;
        });
    },
    handleCancel() {
      this.showForm = false;
    },
    addMendian(app_id) {
      this.app_id = app_id;
      this.form = {
        name: "",
      };
      this.showForm = true;
    },
  },
};
</script>

<style scoped>
.top {
  display: flex;
  flex-direction: column;
}
.ant-col-6 {
  width: 20% !important;
}
.scheme-item {
  width: 100%;
  height: 410px;
  background: #f3f4f5;
  border: 1px solid #ebedf5;
  border-radius: 10px;
  /* transition: all 0.3s; */
  position: relative;
  line-height: 1;
  padding: 10px;
}
.scheme-item:hover {
  transform: translateY(-10px);
}
.scheme-item:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.code {
  display: none !important;
}
.scheme-item:hover .code {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 5px;
  margin-left: -75px;
  margin-top: -75px;
  z-index: 999;
  display: flex !important;
  z-index: 3;
  width: 150px;
  height: 150px;
}
.scheme-item .tui {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 50px;
  height: 50px;
}

.base-app-item {
  width: 580px;
  height: 140px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #ebedf5;
  transition: all 0.3s;
  position: relative;
  line-height: 1;
}
.base-app-item:hover {
  transform: translateY(-10px);
}
.base-app-item .tui {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 50px;
  height: 50px;
}
.base-app-l {
  width: 408px;
}
.base-app-l img {
  width: 100px;
  height: 100px;
}
.base-app-info {
  width: 288px;
}
.test-tag {
  height: 20px;
  line-height: 20px;
  background: rgba(71, 114, 255, 0.1);
  border-radius: 10px;
  color: rgba(71, 114, 255, 1);
  font-size: 12px;
  text-align: center;
  padding: 0 10px;
}
.app-cat {
  margin-left: 20px;
}
.app-cat-item {
  margin-right: 16px;
  padding: 0px 20px;
  height: 32px;
  border-radius: 16px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #232529;
  background: #ffffff;
}
.app-cat-item.on {
  background: #4772ff;
  color: #ffffff;
}
</style>